.Home_suggestedArticles {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
}

@media (max-width: 1024px) {
  .Home_suggestedArticles > * {
    margin-bottom: 20px;
  }
}

.Home_seeAllContainer {
  -ms-grid-column: 6;
  -ms-grid-column-span: 2;
  grid-column: 6/8;
  -ms-grid-column-align: center;
  justify-self: center;
  padding: 80px;
}

@media (max-width: 1024px) {
  .Home_seeAllContainer {
    padding: 50px;
  }
}

.Home_ctaWrapper {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
  padding: 80px 0;
  border-top: 1px solid var(--br-grey);
  border-bottom: 1px solid var(--br-grey);
}

@media (max-width: 1024px) {
  .Home_ctaWrapper {
    padding-bottom: 40px;
    border-top: none;
    border-bottom: none;
  }
}

.Home_editorsWrapper {
  -ms-grid-column: 1;
  -ms-grid-column-span: 12;
  grid-column: 1/13;
}

.Home_editorsWrapper > div > * {
  margin-bottom: 40px;
}
